const convertPxToInch = (rootHeight: number) => {
  // 72 is ppi
  return rootHeight / 72;
};

export const convertInchToPx = ({
  inch,
  componentLength,
  rootHeight,
}: {
  inch: number;
  componentLength: number;
  rootHeight: number;
}) => {
  return inch * (componentLength / convertPxToInch(rootHeight));
};
