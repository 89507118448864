import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import CommentItem from './CommentItem';
import { Comment } from '../../types/Comment';
import AddCommentButton from './AddCommentButton';
import { commentService } from '../../services/CommentService';
import { DocumentNode } from '../../types/RealtaDocument';
import useRole from '../../hooks/useRole';

const CommentSection: React.FC<{ documentUuid: string; node: DocumentNode }> = ({ documentUuid, node }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const { isReadOnly } = useRole();

  const handleSuccessAddComment = (comment: Comment) => {
    setComments([...comments, comment]);
  };

  const handleResolveComment = (id: string) => {
    setComments(comments.map((d) => (d._id === id ? { ...d, isResolved: true } : d)));
  };

  const handleDeleteComment = (id: string, parentId?: string) => {
    if (parentId) {
      setComments(
        comments.map((d) => (d._id === parentId ? { ...d, replies: d.replies?.filter((d) => d._id !== id) } : d))
      );
    } else {
      setComments(comments.filter((d) => d._id !== id));
    }
  };

  const handleReplyComment = (id: string, reply: Comment) => {
    setComments(
      comments.map((d) => (d._id === id ? { ...d, replies: d.replies ? [...d.replies, reply] : [reply] } : d))
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isReadOnly) return;
      const comments = await commentService.getCommentsByReferenceId({ referenceId: node.uuid });
      setComments(comments);
    };

    fetchData();
  }, [node]);

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      bgcolor="transparent"
      gap={3}
      p={2}
      overflow="auto"
    >
      {comments.map((d, i) => (
        <CommentItem
          documentUuid={documentUuid}
          referenceId={node.uuid}
          comment={d}
          key={i}
          onResolve={handleResolveComment}
          onDelete={handleDeleteComment}
          onReply={handleReplyComment}
        />
      ))}
      <AddCommentButton documentUuid={documentUuid} referenceId={node.uuid} onSuccess={handleSuccessAddComment} />
    </Box>
  );
};
export default CommentSection;
