import { DocumentNode } from '../types/RealtaDocument';
import { MAX_ANNOTATION_HEIGHT } from './annotation';
import { convertInchToPx } from './inchToPx';

export const findRelatedNodesByCoordinate = (
  nodes: DocumentNode[],
  coordinate: { x: number; y: number; page: number; height: number },
  rootHeight: number
) => {
  const relatedNodes = nodes
    .filter((node) => {
      // calculate start and end boundryY by page
      const startBoundryY =
        node.startBoundryPage === coordinate.page
          ? convertInchToPx({ inch: node.startBoundryY, componentLength: coordinate.height, rootHeight })
          : 0;
      const endBoundryY =
        node.endBoundryPage === coordinate.page
          ? convertInchToPx({ inch: node.endBoundryY, componentLength: coordinate.height, rootHeight })
          : MAX_ANNOTATION_HEIGHT;

      return startBoundryY <= coordinate.y && endBoundryY >= coordinate.y;
    })
    .sort((a, b) => {
      // only focus on Y axis for now
      // distance will be calculated by the difference between endBoundryY and startBoundryY and page
      // if startBoundryPage is equal than coordinate.page, distance will be calculated by the difference between startBoundryY and coordinate.y
      // or else, it the result will plus the num of pages between startBoundryPage and coordinate.page multiply by MAX_ANNOTATION_HEIGHT
      // same with the endBoundryY
      const startA =
        coordinate.page === a.startBoundryPage
          ? a.startBoundryY
          : // reserve back to previous page, so the value must be negative
            // calculated by number of pages multiply by MAX_ANNOTATION_HEIGHT and plus the distance between end of page to startBoundryY
            MAX_ANNOTATION_HEIGHT * ((a.startBoundryPage || 0) - coordinate.page) -
            (MAX_ANNOTATION_HEIGHT - a.startBoundryY);
      const endA =
        coordinate.page === a.endBoundryPage
          ? a.endBoundryY
          : MAX_ANNOTATION_HEIGHT * (a.endBoundryPage - coordinate.page) + a.endBoundryY;

      const startB =
        coordinate.page === b.startBoundryPage
          ? b.startBoundryY
          : MAX_ANNOTATION_HEIGHT * ((b.startBoundryPage || 0) - coordinate.page) -
            (MAX_ANNOTATION_HEIGHT - b.startBoundryY);
      const endB =
        coordinate.page === b.endBoundryPage
          ? b.endBoundryY
          : MAX_ANNOTATION_HEIGHT * (b.endBoundryPage - coordinate.page) + b.endBoundryY;

      return endA - startA - (endB - startB);
    });

  return relatedNodes;
};
