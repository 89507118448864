import React, { useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { ANNOTATION_WIDTH, Annotation, generateAnnotations } from '../../utils/annotation';
import { DocumentNode } from '../../types/RealtaDocument';
import { navigate } from '@gatsbyjs/reach-router';
import { generateDocumentUrl } from '../../utils/document-url';

const AnnotationBar: React.FC<{
  documentUuid: string;
  height: number;
  page: number;
  node?: DocumentNode | null;
  annotations: Annotation[];
  isVisible?: boolean;
  rootHeight: number;
}> = ({ documentUuid, height, page, node, annotations, isVisible = true, rootHeight }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  // NOTE: container ref to calculate the left and top offset, canvas uses absolute positioning thats why cannot get offsets
  const containerRef = useRef<HTMLDivElement | null>(null);
  const queryParams = new URLSearchParams(location.search);

  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.height = height;
    }
  }, [height]);

  const mergedAnnotations = useMemo(
    () =>
      annotations && annotations.length > 0
        ? annotations
        : node && node.uuid
        ? generateAnnotations({
            nodes: [node],
            page,
            componentHeight: height,
            rootHeight,
          })
        : [],
    [annotations, height, node, page, rootHeight]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    // canvas width is calculated by the number of mergedAnnotations plus the number of gaps between mergedAnnotations
    canvas.width =
      mergedAnnotations.length > 0
        ? ANNOTATION_WIDTH * mergedAnnotations.length + (ANNOTATION_WIDTH / 1.6) * (mergedAnnotations.length - 1) + 4
        : 0;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.reset();

    if (mergedAnnotations.length === 0) return;
    ctx.lineWidth = ANNOTATION_WIDTH;

    mergedAnnotations.forEach((annotation) => {
      ctx.strokeStyle = annotation.color;
      ctx.beginPath();
      ctx.moveTo(annotation.startX, annotation.startY);
      ctx.lineTo(annotation.endX, annotation.endY);
      ctx.stroke();
    });
  }, [mergedAnnotations]);

  const handleClickCanvas = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const container = containerRef.current;
    const canvas = canvasRef.current;
    if (!canvas || !container) return;
    // NOTE: mouseX is calculated by getting mouse position minus the offset of the container,
    // then add the width of the canvas cause the canvas position from the right
    const mouseX = e.clientX - container.offsetLeft + canvas.width;
    // NOTE: 48 is the height of the header and 48 from toolbar
    const mouseY = e.clientY - 48 - 48;
    // console.log(`Clicked on annotation bar at (${mouseX}, ${mouseY})`);

    mergedAnnotations.forEach((annotation) => {
      const { startX, startY, endY } = annotation;

      // Check if the click falls on the annotation's path
      if (mouseX >= startX - ANNOTATION_WIDTH && mouseX <= startX + ANNOTATION_WIDTH) {
        if (mouseY >= startY && mouseY <= endY) {
          navigate(
            generateDocumentUrl({
              documentId: documentUuid,
              sectionId: annotation.node.uuid,
              page,
              view: queryParams.get('view') || undefined,
            })
          );
          // console.log('Clicked on annotation');

          // Create a popup to show the annotation's name
          const popup = document.createElement('div');
          popup.innerText = `${annotation.type}: ${annotation.node.fullId || annotation.node.content || 'Unnamed'}`;
          popup.style.position = 'absolute';
          popup.style.top = `${e.clientY}px`;
          popup.style.left = `${e.clientX + 10}px`;
          popup.style.backgroundColor = 'white';
          popup.style.padding = '5px';
          popup.style.border = '1px solid black';
          popup.style.zIndex = '1001';
          document.body.appendChild(popup);

          setTimeout(() => {
            document.body.removeChild(popup);
          }, 3000);
        }
      }
    });
  };

  return (
    // NOTE: container ref to calculate the left and top offset, canvas uses absolute positioning thats why cannot get offsets
    <Box width="0px" position="relative" height="full" ref={containerRef} display={isVisible ? 'block' : 'none'}>
      <Box
        component={'canvas'}
        ref={canvasRef}
        sx={{ position: 'absolute', zIndex: 1000, top: 0, bottom: 0, right: 0, opacity: 0.3 }}
        onClick={handleClickCanvas}
      />
    </Box>
  );
};

export default AnnotationBar;
