import React from 'react';
import { useDispatch } from 'react-redux';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';
import { ToolbarVertical } from '../ui/ToolbarVertical/ToolbarVertical';
import { trackEvent, util } from '../../services';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { openResultsPanel } from '../../store/setting';
import { Box } from '@mui/material';

const titleStyle = {
  marginTop: '5px',
  writingMode: 'vertical-rl',
  textOrientation: 'mixed',
  fontWeight: 600,
  fontSize: '.85em',
};

const ResultsPanelClosed: React.FC = () => {
  const dispatch = useDispatch();

  const handleExpand = (event: any) => {
    dispatch(openResultsPanel());
    trackEvent.resultsPanelOpened();
    util.fireSplitPaneResizeEvent(Math.random());
    event.stopPropagation();
  };

  return (
    <ToolbarVertical variant="dense" disableGutters={true} title="Open" onClick={handleExpand}>
      <ToolbarIconButton onClick={handleExpand} title="Open">
        <ChevronLeftIcon />
      </ToolbarIconButton>
      <Box sx={titleStyle}>Results</Box>
    </ToolbarVertical>
  );
};

export { ResultsPanelClosed };
