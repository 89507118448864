import { getMsalInstance } from '../utils/get-msal-instance';

const useUsername = () => {
  const msalInstance = getMsalInstance();
  const account = msalInstance.getActiveAccount();

  return account?.username || '';
};

export default useUsername;
