import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { ATOM_REGEX, Atom } from '../../types/RealtaDocument';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCreateAtom, useUpdateAtom } from '../../services/queries';
import useRole from '../../hooks/useRole';

type CreateEditAtomDialogProps = {
  open: boolean;
  codeBlockId: string;
  isEdit?: boolean;
  atom?: Atom;
  onClose: () => Promise<void>;
  onCreate?: () => Promise<void>;
  onEdit?: () => Promise<void>;
};

const schema = () =>
  yup.object().shape({
    type: yup
      .string()
      .required('Type is required')
      .test('notBlank', 'Type is required', (value) => !!value),
    name: yup
      .string()
      .required('Name is required')
      .test('validName', 'Name is invalid', (value) => !!value && ATOM_REGEX.test(value)),
    comment: yup.string(),
  });

type CreateEditAtomFormType = {
  type: string;
  name: string;
  comment: string;
};

// types: Atom, Numeric, String, DateTime, Date, Duration
const ATOM_TYPES_OPTIONS = [
  { value: 'ATOM', label: 'Atom' },
  { value: 'NUMERIC', label: 'Numeric' },
  { value: 'STRING', label: 'String' },
  { value: 'DATETIME', label: 'DateTime' },
  { value: 'DATE', label: 'Date' },
  { value: 'DURATION', label: 'Duration' },
];

const CreateEditAtomDialog: React.FC<CreateEditAtomDialogProps> = ({
  open,
  codeBlockId,
  atom,
  isEdit,
  onClose,
  onCreate,
  onEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: createAtom, isLoading: isLoadingCreateAtom } = useCreateAtom();
  const { mutateAsync: updateAtom, isLoading: isLoadingUpdateAtom } = useUpdateAtom();
  const { isAdmin } = useRole();

  const { handleSubmit, errors, control, reset } = useForm<CreateEditAtomFormType>({
    defaultValues: {
      type: atom?.type || 'ATOM',
      name: atom?.name ? (isEdit ? atom.name : `${atom.name}_copy`) : '',
      comment: atom?.comment || '',
    },
    resolver: yupResolver(schema()),
  });

  useEffect(() => {
    if (atom) {
      reset({
        type: atom.type,
        name: isEdit ? atom.name : `${atom.name}_copy`,
        comment: atom.comment || undefined,
      });
    }
  }, [atom, isEdit]);

  const handleClose = async () => {
    reset();
    await onClose();
  };

  const onSubmit = async (data: CreateEditAtomFormType) => {
    try {
      if (atom) {
        await updateAtom({ ...data, uuid: atom.uuid });
        onEdit && (await onEdit());
      } else {
        await createAtom({
          ...data,
          parentNode: codeBlockId,
        });
        onCreate && (await onCreate());
      }
      await handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(isEdit ? 'Failed to update atom' : 'Failed to create atom', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{isEdit ? 'Update Atom' : 'Create Atom'}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl sx={{ mt: 1 }} color="secondary">
            <InputLabel id="type">Type</InputLabel>
            <Controller
              control={control}
              name="type"
              as={
                <Select
                  labelId="type"
                  id="select-type"
                  label="Type"
                  error={!!errors.type}
                  fullWidth
                  disabled={!isAdmin}
                >
                  {ATOM_TYPES_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
          <Controller
            control={control}
            name="name"
            render={({ ...fields }) => (
              <TextField
                {...fields}
                label="Name"
                fullWidth
                color="secondary"
                error={!!errors.name}
                helperText={errors.name?.message}
                disabled={!isAdmin}
              />
            )}
          />
          <Controller
            control={control}
            name="comment"
            render={({ ...fields }) => (
              <TextField
                {...fields}
                label="Comment"
                fullWidth
                color="secondary"
                multiline
                rows={6}
                error={!!errors.comment}
                helperText={errors.comment?.message}
                disabled={!isAdmin}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            color="inherit"
            onClick={handleClose}
            disabled={isLoadingCreateAtom || isLoadingUpdateAtom}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            autoFocus
            color="secondary"
            disabled={!isAdmin || isLoadingCreateAtom || isLoadingUpdateAtom}
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateEditAtomDialog;
