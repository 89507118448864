import { apollo } from '../apollo/client';
import gql from 'graphql-tag';
import { ReasonerSummary, InputFact } from '../types/RealtaTesting';

const RUN_REASONER_QUERY = gql`
  query RUN_REASONER_QUERY($startNode: String!, $facts: String!) {
    runReasoner(startNode: $startNode, facts: $facts) {
      facts {
        name
        modes
      }
      missingFacts
    }
  }
`;

const callReasoner = async (startNode: string, facts: InputFact[]): Promise<ReasonerSummary> => {
  /* Do the messy serialization for the call here */
  var serialisedFacts = '{';
  for (var f = 0; f < facts.length; f++) {
    serialisedFacts = serialisedFacts + facts[f].name + ':"' + facts[f].mode + '"';
    if (f < facts.length - 1) {
      serialisedFacts = serialisedFacts + ',';
    }
  }
  serialisedFacts = serialisedFacts + '}';

  const result = await runReasoner(startNode, serialisedFacts);

  return result;
};

const runReasoner = async (startNode: string, facts: string): Promise<ReasonerSummary> => {
  const { data } = await apollo.mutate({
    mutation: RUN_REASONER_QUERY,
    variables: {
      startNode,
      facts,
    },
  });
  return data?.runReasoner;
};

export const ReasonerService = {
  callReasoner,
  runReasoner,
};
