import { useAnimation, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  CircularProgress,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandMore } from '@mui/icons-material';
import { CodeGenDebug } from '../../types/RealtaDocument';
import CodeGenDebugStatus from './CodeGenDebugStatus';

const useStyles = makeStyles({
  resultsPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
});

const CodeGenDebugPanel = ({
  onClosePanel,
  codeGenDebug,
  isFetching = false,
}: {
  codeGenDebug: CodeGenDebug | null;
  isFetching?: boolean;
  onClosePanel: () => void;
}) => {
  const classes = useStyles();
  const controls = useAnimation();

  useEffect(() => {
    controls.set({ opacity: 0 });
    controls.start({ opacity: 1, transition: { duration: 2 } });
  }, []);

  const handleCollapse = () => {
    onClosePanel();
  };

  return (
    <>
      <motion.article className={classes.resultsPanel} animate={controls}>
        <AppBar position="static" color="inherit" sx={{ backgroundColor: 'grey.200', zIndex: 10 }}>
          <Toolbar variant="dense" disableGutters={true}>
            <Box flexGrow={1} pl={1} display="flex" alignItems="center" gap={1}>
              <Typography fontWeight={600}>CodeGenDebug</Typography>
              <CodeGenDebugStatus isSuccess={!!codeGenDebug?.success} />
            </Box>
            <div>
              <ToolbarIconButton aria-label="close results panel" onClick={handleCollapse} title="Close">
                <CloseIcon fontSize="small" />
              </ToolbarIconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Box p={1} flexGrow={1} display="flex" flexDirection="column" gap={2} height="1px" sx={{ overflowY: 'auto' }}>
          {isFetching && <CircularProgress size="sm" color="secondary" sx={{ mx: 'auto', display: 'block' }} />}
          {codeGenDebug ? (
            <Stack gap={1}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <b>Output Message</b>
                </AccordionSummary>
                <AccordionDetails>{codeGenDebug.outputMessage}</AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <b>Sample Links</b>
                </AccordionSummary>
                <AccordionDetails>
                  {codeGenDebug.sampleLinks.map((link, i) => (
                    <li key={i}>
                      <a href={link} target="_blank">
                        Sample link {i + 1}
                      </a>
                    </li>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <b>Type</b>
                </AccordionSummary>
                <AccordionDetails>{codeGenDebug.type}</AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <b>Initial Turnip</b>
                </AccordionSummary>
                <AccordionDetails>{codeGenDebug.initialTurnip}</AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2-content" id="panel2-header">
                  <b>Final Turnip</b>
                </AccordionSummary>
                <AccordionDetails>{codeGenDebug.finalTurnip}</AccordionDetails>
              </Accordion>
            </Stack>
          ) : (
            <Box>No data</Box>
          )}
        </Box>
      </motion.article>
    </>
  );
};

export { CodeGenDebugPanel };
