import React from 'react';
import { Box, Button, Chip, Typography } from '@mui/material';
import { Tag } from '../../types/RealtaDocument';
import uniqolor from 'uniqolor';
import { Close } from '@mui/icons-material';
import { useDeleteTag } from '../../services/queries';
import useRole from '../../hooks/useRole';
import { useSnackbar } from 'notistack';

const TagItem: React.FC<{ data: Tag; nodeUuid: string }> = ({ data, nodeUuid }) => {
  const { isReadOnly } = useRole();
  const { mutateAsync: deleteTag, isLoading } = useDeleteTag();
  const { enqueueSnackbar } = useSnackbar();

  const isDisabled = isReadOnly || isLoading;

  const handleDelete = async () => {
    try {
      await deleteTag({ tagUuid: data.uuid, nodeUuid });
      enqueueSnackbar(`Tag ${data.name} deleted successfully`, { variant: 'success' });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error?.message ? error.message.replace('GraphQL error: ', '') : 'Failed to delete tag', {
        variant: 'error',
      });
    }
  };

  return (
    <Chip
      label={
        <Box display="flex" gap={1}>
          <Typography>{data.name}</Typography>
          <Button onClick={handleDelete} size="small" sx={{ p: 0, minWidth: 'unset' }} disabled={isDisabled}>
            <Close
              sx={{ color: 'black', fontSize: 16, border: '1px solid black', borderRadius: '100%', bgcolor: '#eee' }}
            />
          </Button>
        </Box>
      }
      sx={{
        bgcolor: uniqolor(data.uuid, {
          saturation: [35, 70],
          lightness: 80,
        }).color,
      }}
    />
  );
};
export default TagItem;
