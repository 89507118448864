import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, Snackbar, Toolbar } from '@mui/material';
import grey from '@mui/material/colors/grey';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { openResultsPanel } from '../../store/setting';
import { TestCaseStatus } from '../../store/testCase';
import { TestCaseStatusIcon } from '../ui/TestCaseStatusIcon/TestCaseStatusIcon';
import { EditableTitle } from '../ui';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';
import { TestCaseMoreOption } from './TestCaseMoreOption';
import SaveIcon from '@mui/icons-material/Save';
import { testSelectors, updateTest } from '../../store/test';
import { documentService } from '../../services/DocumentService';
import { testService } from '../../services/testService';
import useRole from '../../hooks/useRole';
import useUsername from '../../hooks/useUsername';
import { Test } from '../../types/RealtaDocument';
import { useSnackbar } from 'notistack';

const root = {
  backgroundColor: grey[200],
  position: 'relative',
};

const TestCaseToolbar: React.FC<{ onSave?: (test: Test) => void }> = ({ onSave }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const selectedTest = useSelector(testSelectors.selectedTest);
  const { isReadOnly } = useRole();
  const username = useUsername();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSave = () => {
    setLoading(true);
    try {
      if (selectedTest) {
        documentService.updateTest({ ...selectedTest, updatedBy: username });
        setOpen(true);
        onSave && onSave({ ...selectedTest, updatedBy: username });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error saving test case', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleTitleChange = (value: string) => selectedTest && dispatch(updateTest({ ...selectedTest, name: value }));

  const handleRun = async () => {
    setLoading(true);
    try {
      if (selectedTest) {
        const updatedTest = await testService.runTestCase(selectedTest);
        await documentService.updateTest({ ...updatedTest, updatedBy: username });
        dispatch(updateTest(updatedTest));
        dispatch(openResultsPanel());
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error running test case', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppBar position="static" color="inherit" sx={root}>
      <Toolbar variant="dense" disableGutters={true} sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          <div
            style={{
              marginLeft: '4px',
            }}
          >
            <TestCaseStatusIcon status={(selectedTest?.status || TestCaseStatus.NA) as TestCaseStatus} />
          </div>
          <EditableTitle
            titlePlaceholder={'Untitled test case'}
            inputPlaceholder="Enter title"
            onChange={handleTitleChange}
            value={selectedTest?.name}
            disabled={isReadOnly}
          />
        </Box>
        <div
          style={{
            minWidth: '120px',
            textAlign: 'right',
            flexShrink: 0,
          }}
        >
          <ToolbarIconButton
            aria-label="save turnip reasoner"
            title="Save"
            onClick={handleSave}
            color="secondary"
            disabled={loading || isReadOnly}
          >
            <SaveIcon titleAccess="Save" color={loading || isReadOnly ? 'inherit' : 'secondary'} fontSize="small" />
          </ToolbarIconButton>
          <ToolbarIconButton
            aria-label="run turnip reasoner"
            title="Run"
            onClick={handleRun}
            color="secondary"
            disabled={loading || isReadOnly}
          >
            <PlayCircleFilledWhiteIcon
              titleAccess="Run"
              color={loading || isReadOnly ? 'inherit' : 'secondary'}
              fontSize="small"
            />
          </ToolbarIconButton>
          <TestCaseMoreOption isReadOnly={isReadOnly} />
        </div>
      </Toolbar>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} message="Test case is updated" />
    </AppBar>
  );
};

export { TestCaseToolbar };
