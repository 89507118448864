import React from 'react';

import { useLocation } from '@gatsbyjs/reach-router';
import DocumentDetails from './DocumentDetails';
import DocumentList from './DocumentList';

const DocumentPage: React.FC<{}> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const documentId = queryParams.get('document-id');

  return documentId ? <DocumentDetails documentUuid={documentId} /> : <DocumentList />;
};
export default DocumentPage;
