import React, { useEffect, useRef, useState } from 'react';
import { Box, LinearProgress, Tooltip } from '@mui/material';
import Plus from '../../images/plus.svg';
import Minus from '../../images/minus.svg';
import { documentService } from '../../services/DocumentService';
import { Reference } from '../../types/RealtaDocument';
import { navigate } from '@gatsbyjs/reach-router';
import { generateDocumentUrl } from '../../utils/document-url';
import uniqolor from 'uniqolor';
import { useSnackbar } from 'notistack';

const ReferenceItem: React.FC<{ data: Reference; position: number; upstream: boolean; rootNodeIds: string[] }> = ({
  data,
  position,
  upstream,
  rootNodeIds,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [references, setReferences] = useState<Reference[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const buttonRef = useRef<any>(null);

  const isEnableClicking = !!data.section.startBoundryPage;

  useEffect(() => {
    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isEnableClicking) {
        const url = generateDocumentUrl({
          documentId: data.document.uuid,
          sectionId: data.section.uuid,
          page: data.section.startBoundryPage || '1',
          view: 'References',
        });
        if ((event.ctrlKey && event.button === 0) || (event.metaKey && event.button === 0)) {
          const newTab = window.open('', '_blank');
          newTab!.location = url;
        } else {
          navigate(url);
        }
      }
    };

    const buttonElement = buttonRef.current;
    if (buttonElement) {
      buttonElement.addEventListener('mousedown', (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        handleMouseDown(e)
      );
    }

    return () => {
      if (buttonElement) {
        buttonElement.removeEventListener('mousedown', (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          handleMouseDown(e)
        );
      }
    };
  }, []);

  const handleOpen = async () => {
    setLoading(true);
    if (!open) {
      try {
        const res = await documentService.getSectionReferences({
          uuid: data.section.uuid || '',
          upstream: upstream,
          rootNodeIds,
        });

        if (res) {
          setReferences(res);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to fetch references', { variant: 'error' });
      }
    }

    setOpen((open) => !open);
    setLoading(false);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" flexShrink={0}>
        {data.hasChildren ? (
          <Box
            component="img"
            sx={{
              width: 50,
              height: 54,
              flexShrink: 0,
            }}
            src={open ? Minus : Plus}
            onClick={handleOpen}
          />
        ) : (
          <Box
            sx={{
              width: 50,
              height: 54,
              flexShrink: 0,
            }}
          />
        )}
        <Box display="flex" alignItems="center" flexGrow={1} border="1px solid #c9c9c9" height={54} flexShrink={0}>
          <Box
            bgcolor="#5e5e5e"
            color="#fff"
            width={40}
            textAlign="center"
            height="100%"
            lineHeight="54px"
            flexShrink={0}
          >
            {position}
          </Box>
          <Box
            p={1.25}
            flexGrow={1}
            display="flex"
            gap={2}
            alignItems="center"
            sx={{ cursor: isEnableClicking ? 'pointer' : 'normal' }}
            ref={buttonRef}
          >
            <Tooltip title={data.document.displayName || ''}>
              <Box
                p={1}
                bgcolor={
                  uniqolor(data.document.uuid, {
                    saturation: [35, 70],
                    lightness: 60,
                  }).color
                }
                borderRadius={2}
                width={200}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {data.document.displayName}
              </Box>
            </Tooltip>
            <Box>
              <Box fontWeight="bold">{data.vSection.fullId?.replace(/, $/, '')}</Box>
              <Box>{data.INT_REF_DIRECT.Text}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {loading && <LinearProgress color="secondary" />}
      {!!references?.length && open && (
        <Box pl={2.75} my={1} display="flex" flexDirection="column" gap={1}>
          {references.map((ref, i) => (
            <ReferenceItem
              key={ref.section.uuid}
              data={ref}
              position={i + 1}
              upstream={upstream}
              rootNodeIds={[...rootNodeIds, ref.section.uuid || '']}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
export default ReferenceItem;
