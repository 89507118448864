/* eslint-disable react-hooks/exhaustive-deps */
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import AnnotationBar from './AnnotationBar';
import { findRelatedNodesByCoordinate } from '../../utils/findRelatedNodesByCoordinate';
import { DocumentNode } from '../../types/RealtaDocument';
import { documentService } from '../../services/DocumentService';
import { generateAnnotations } from '../../utils/annotation';
import { navigate } from '@gatsbyjs/reach-router';
import { generateDocumentUrl } from '../../utils/document-url';
import { getDocument } from 'pdfjs-dist';
import { SOURCE_URL, getPdfFromUrl } from '../../utils/pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfView: React.FC<{
  documentUuid: string;
  node?: DocumentNode | null;
  page: number;
  showAnnotations?: boolean;
  pdfSize: { width: number | undefined; height: number | undefined };
  setPdfSize: (size: { width: number | undefined; height: number | undefined }) => void;
  showFooter?: boolean;
  showChangeAlert?: boolean;
  setShowChangeAlert?: (value: boolean) => void;
}> = ({
  documentUuid,
  node,
  page,
  showAnnotations = true,
  pdfSize,
  setPdfSize,
  showFooter = true,
  showChangeAlert = false,
  setShowChangeAlert = () => {},
}) => {
  const queryParams = new URLSearchParams(location.search);
  const pdfRef = useRef<HTMLCanvasElement | null>();

  const [numPages, setNumPages] = useState<number>(0);
  const [rootHeightForCalculatingPixel, setRootHeightForCalculatingPixel] = useState<number>(0);

  const [pageSections, setPageSections] = useState<DocumentNode[]>([]);
  const [focusSections, setFocusSections] = useState<DocumentNode[]>([]);

  const [pageParagraphs, setPageParagraphs] = useState<DocumentNode[]>([]);
  const [focusParagraphs, setFocusParagraphs] = useState<DocumentNode[]>([]);

  const url = useMemo(() => `${SOURCE_URL}/${documentUuid}/source.pdf`, [documentUuid]);

  const filterFocusSectionsByPage = useCallback(
    (newPage: number) => {
      setFocusSections(
        focusSections.filter(
          (section) => (section.startBoundryPage || 0) <= newPage && section.endBoundryPage >= newPage
        )
      );
      setFocusParagraphs(
        focusParagraphs.filter((p) => (p.startBoundryPage || 0) <= newPage && p.endBoundryPage >= newPage)
      );
    },
    [focusSections]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (pdfRef.current) {
        const { width, height } = await getRootDocSize(url, page);

        const maxHeight = window.innerHeight - 40 - 48 - 48;
        const maxWidth = window.innerWidth / 2;
        let calculatedHeight, calculatedWidth;

        if (width > height) {
          if (width !== maxWidth) {
            const ratio = maxWidth / width;
            calculatedWidth = maxWidth;
            calculatedHeight = height * ratio;
          }
        } else {
          if (height !== maxHeight) {
            const ratio = maxHeight / height;
            calculatedHeight = maxHeight;
            calculatedWidth = width * ratio;
          }
        }

        if (pdfRef.current?.style) {
          pdfRef.current.style.width = `${calculatedWidth}px`;
          pdfRef.current.style.height = `${calculatedHeight}px`;
        }
        setRootHeightForCalculatingPixel(height);

        setPdfSize({ width: calculatedWidth, height: calculatedHeight });
      }

      const sections = await documentService.getDocumentSectionsByPage({ documentUuid, page });
      setPageSections(sections);
      const paragraphs = await documentService.getDocumentParagraphsByPage({ documentUuid, page });
      setPageParagraphs(paragraphs);
    };

    if (!page) {
      return;
    }
    filterFocusSectionsByPage(page);

    fetchData();
  }, [documentUuid, page]);

  const annotations = useMemo(() => {
    return generateAnnotations({
      nodes: [...focusParagraphs, ...focusSections],
      page,
      componentHeight: pdfSize.height || 0,
      rootHeight: rootHeightForCalculatingPixel,
    });
  }, [focusSections, page, pdfSize]);

  const onPageChange = async (newPage: number) => {
    if (showChangeAlert) {
      const shouldNavigate = window.confirm('Changes you made may not be saved.');
      if (!shouldNavigate) {
        return;
      }
    }
    setShowChangeAlert(false);
    if (node && node.startBoundryPage && newPage >= node.startBoundryPage && newPage <= node.endBoundryPage) {
      navigate(
        generateDocumentUrl({
          documentId: documentUuid,
          sectionId: node.uuid,
          page: newPage,
          view: queryParams.get('view') || undefined,
        }),
        {
          replace: true,
        }
      );
    } else {
      navigate(
        generateDocumentUrl({ documentId: documentUuid, page: newPage, view: queryParams.get('view') || undefined }),
        {
          replace: true,
        }
      );
    }
  };

  async function getRootDocSize(url: string, page: number) {
    const pdfData = await getPdfFromUrl(url);
    const loadingTask = getDocument({ data: pdfData });
    const pdfDoc = await loadingTask.promise;
    const selectedPage = await pdfDoc.getPage(page);
    const viewport = selectedPage.getViewport({ scale: 1 });
    return { height: viewport.height, width: viewport.width };
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function onDocumentLoadFailed(): void {
    navigate(generateDocumentUrl(), {
      replace: true,
    });
  }

  function previousPage() {
    onPageChange(page - 1);
  }

  function nextPage() {
    onPageChange(page + 1);
  }

  const handleClickPdf = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const pdf = pdfRef.current;
    if (!pdf) return;

    const x = e.clientX - pdf.getBoundingClientRect().left;
    const y = e.clientY - pdf.getBoundingClientRect().top;

    setFocusSections(
      findRelatedNodesByCoordinate(
        pageSections,
        { x, y, page, height: pdfSize.height || 0 },
        rootHeightForCalculatingPixel
      )
    );
    setFocusParagraphs(
      findRelatedNodesByCoordinate(
        pageParagraphs,
        { x, y, page, height: pdfSize.height || 0 },
        rootHeightForCalculatingPixel
      )
    );

    // console.log(`Clicked at (${x}, ${y}) within the PDF viewer`);
    // console.log(findRelatedNodesByCoordinate(pageSections, { x, y, page: pageNumber, height: pdfSize.height }));
    // console.log(findRelatedNodesByCoordinate(pageParagraphs, { x, y, page: pageNumber, height: pdfSize.height }));
  };

  const handleDoubleClickPdf = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const pdf = pdfRef.current;
    if (!pdf) return;

    const x = e.clientX - pdf.getBoundingClientRect().left;
    const y = e.clientY - pdf.getBoundingClientRect().top;
    const nearestNode = findRelatedNodesByCoordinate(
      [...pageParagraphs, ...pageSections],
      {
        x,
        y,
        page,
        height: pdfSize.height || 0,
      },
      rootHeightForCalculatingPixel
    )?.[0];
    if (nearestNode) {
      navigate(
        generateDocumentUrl({
          documentId: documentUuid,
          sectionId: nearestNode.uuid,
          page,
          view: queryParams.get('view') || undefined,
        })
      );
    }
  };

  const handleChangePageField = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPage = +e.target.value;
    if (newPage >= 1 && newPage <= numPages) {
      onPageChange(+e.target.value);
    }
  };

  return (
    <Box display="flex" flexGrow={1}>
      <Box width={pdfSize.width} display="flex" flexDirection="column" justifyContent="space-between">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadFailed}
          // @ts-ignore: Ref type
          inputRef={pdfRef}
          onClick={handleClickPdf}
          onDoubleClick={handleDoubleClickPdf}
          loading={
            <Box
              width={pdfSize.width}
              height={pdfSize.height}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={60} />
            </Box>
          }
        >
          {/* This box to keep pdf pages are always in the same size */}
          <Box width={pdfSize.width} height={pdfSize.height} overflow="hidden">
            <Page pageNumber={page} width={pdfSize.width} />
          </Box>
        </Document>
        {showFooter && (
          <Box display="flex" height={48} pb={1} bgcolor="#000" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={1} alignItems="center" ml={2} px={0.5} bgcolor="#fff">
              <Typography>Page</Typography>
              <TextField
                variant="standard"
                type="number"
                value={page}
                onChange={handleChangePageField}
                sx={{
                  padding: '0 !important',
                  width: 60,
                  input: { padding: '0 !important' },
                }}
              />
              <Typography>/ {numPages}</Typography>
            </Box>
            <Box display="flex" gap={2}>
              <Button type="button" disabled={page <= 1} onClick={previousPage} sx={{ color: '#fff' }}>
                Previous
              </Button>
              <Button type="button" disabled={page >= numPages} onClick={nextPage} sx={{ color: '#fff' }}>
                Next
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <AnnotationBar
        documentUuid={documentUuid}
        height={pdfSize.height || 0}
        node={node}
        annotations={annotations}
        isVisible={showAnnotations}
        page={page}
        rootHeight={rootHeightForCalculatingPixel}
      />
    </Box>
  );
};
export default PdfView;
