import { TestCaseStatus } from '../store/testCase';
import { Test } from '../types/RealtaDocument';
import { InputFact } from '../types/RealtaTesting';
import { ReasonerService } from './ReasonerService';

const runTestCase = async (test: Test) => {
  try {
    const reasonerService = ReasonerService;

    // Get facts ready
    const inputFacts: InputFact[] = parseFactsText(test);

    // Make the call
    if (!test.startNode) {
      test.status = TestCaseStatus.FAIL;
      test.result = 'No start node was supplied';
      return test;
    }

    const result = await reasonerService.callReasoner(test.startNode, inputFacts);
    var message = '';

    // Create message for facts
    result.facts.forEach(function (fact) {
      // Get the matching inputFact
      message += fact.name + ' ';
      fact.modes.forEach((mode, index) => {
        message += mode;
        if (index + 1 < fact.modes.length) {
          message += ',';
        }
      });
      message += '\n';
    });

    // Create message for missing facts
    if (result.missingFacts.length > 0) {
      message += '\n\n--Missing Facts--\n\n';
      result.missingFacts.forEach(function (missingFact) {
        message += missingFact + '\n';
      });
    }

    test.result = message;
    test.status = TestCaseStatus.SUCCESS;

    return test;
  } catch (error) {
    console.error('Error running test case', error);
    test.status = TestCaseStatus.FAIL;
    return test;
  }
};

const parseFactsText = (test: Test) => {
  // Split based on new line characters
  const inputFacts: InputFact[] = [];
  if (test.fact === null) {
    return inputFacts;
  }

  const facts = test.fact.split('\n');

  facts.forEach(function (fact) {
    if (fact.startsWith('~')) {
      // IsNot
      const factName = fact.substring(1);
      const isNotFact = new InputFact(factName, 'IsNot');
      inputFacts.push(isNotFact);
    } else if (fact.startsWith('[O]')) {
      // Obligatory
      const factName = fact.substring(3);
      const obligatoryFact = new InputFact(factName, 'Obligatory');
      inputFacts.push(obligatoryFact);
    } else if (fact.startsWith('[P]')) {
      // Permissible
      const factName = fact.substring(3);
      const permittedName = new InputFact(factName, 'Permitted');
      inputFacts.push(permittedName);
    } else if (fact.startsWith('[F]')) {
      // Forbidden
      const factName = fact.substring(3);
      const forbiddenName = new InputFact(factName, 'Forbidden');
      inputFacts.push(forbiddenName);
    } else if (fact.startsWith('[E]')) {
      // Excused
      const factName = fact.substring(3);
      const excusedName = new InputFact(factName, 'Excused');
      inputFacts.push(excusedName);
    } else {
      // Is
      const isFact = new InputFact(fact, 'Is');
      inputFacts.push(isFact);
    }
  });

  return inputFacts;
};

export const testService = {
  runTestCase,
};
