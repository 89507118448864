import React, { memo, useEffect, useState } from 'react';
import { DocumentDiffChangeMap, DocumentNode } from '../../types/RealtaDocument';
import { navigate } from '@gatsbyjs/reach-router';
import TOCItem from './TOCItem';
import { generateDocumentUrl } from '../../utils/document-url';
import { documentService } from '../../services/DocumentService';
import { LinearProgress } from '@mui/material';
import { CommentCount } from '../../types/Comment';

const TOCList: React.FC<{
  headings: DocumentNode[];
  documentUuid: string;
  loadComment: boolean;
  diffChanges?: DocumentDiffChangeMap;
}> = ({ headings, documentUuid, loadComment, diffChanges }) => {
  const queryParams = new URLSearchParams(location.search);

  const [commentCounts, setCommentCounts] = useState<CommentCount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      if (loadComment) {
        setIsLoading(true);
        const commentCounts = await documentService.getNodeSubSectionComments({ uuid: documentUuid, isDocument: true });
        setCommentCounts(commentCounts);
        setIsLoading(false);
      }
    };

    init();
  }, [documentUuid, loadComment]);

  const handleClickHeading = (heading: DocumentNode) => {
    if (heading.startBoundryPage) {
      navigate(
        generateDocumentUrl({
          documentId: documentUuid,
          sectionId: heading.uuid,
          page: heading.startBoundryPage,
          view: queryParams.get('view') || undefined,
        })
      );
    }
  };

  return (
    <>
      {isLoading && <LinearProgress color="secondary" />}
      {headings.map((section) => (
        <TOCItem
          key={section.uuid}
          node={section}
          documentId={documentUuid}
          onSelect={handleClickHeading}
          loadComment={loadComment}
          commentCount={commentCounts.find((c) => c.id === section.uuid)}
          diffChanges={diffChanges}
        />
      ))}
    </>
  );
};
export default memo(TOCList);
