import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar } from '@mui/material';
import grey from '@mui/material/colors/grey';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import AddIcon from '@mui/icons-material/Add';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';
import { Test } from '../../types/RealtaDocument';
import { testService } from '../../services/testService';
import { documentService } from '../../services/DocumentService';
import { testSelectors, updateTest } from '../../store/test';
import useRole from '../../hooks/useRole';
import useUsername from '../../hooks/useUsername';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    marginLeft: '15px',
    fontWeight: 600,
  },
}));

type TestCaseListToolbarProps = {
  onCreate: () => void;
};

const TestCaseListToolbar: React.FC<TestCaseListToolbarProps> = ({ onCreate }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const allTestCases = useSelector(testSelectors.tests);
  const { isReadOnly } = useRole();
  const username = useUsername();

  const [loading, setLoading] = React.useState(false);

  const runTestCase = async (testCase: Test) => {
    try {
      const updatedTest = await testService.runTestCase(testCase);
      await documentService.updateTest({ ...updatedTest, updatedBy: username });
      dispatch(updateTest(updatedTest));
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error running test case', { variant: 'error' });
    }
  };

  const handleRunAllTestCases = async () => {
    setLoading(true);
    for (const testCase of allTestCases) {
      await runTestCase(testCase);
    }
    setLoading(false);
  };

  return (
    <AppBar position="static" color="inherit" sx={{ backgroundColor: grey[300] }}>
      <Toolbar variant="dense" disableGutters={true}>
        <div className={classes.grow}>
          <span className={classes.title}>Test cases</span>
        </div>
        <div style={{ marginRight: '5px' }}>
          <ToolbarIconButton
            aria-label="Create new test case"
            onClick={onCreate}
            title="Create new test case"
            disabled={isReadOnly}
          >
            <AddIcon fontSize="small" />
          </ToolbarIconButton>
          <ToolbarIconButton
            aria-label="Run all test cases"
            onClick={handleRunAllTestCases}
            title="Start watch mode"
            disabled={loading || isReadOnly}
          >
            <PlayCircleFilledWhiteIcon fontSize="small" color={loading || isReadOnly ? 'inherit' : 'secondary'} />
          </ToolbarIconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export { TestCaseListToolbar };
