import React, { useRef } from 'react';
import { Typography, Box, Button, TextField } from '@mui/material';
import { commentService } from '../../services/CommentService';
import { Comment } from '../../types/Comment';
import useRole from '../../hooks/useRole';
import useUsername from '../../hooks/useUsername';

interface AddCommentButtonProps {
  documentUuid: string;
  referenceId: string;
  onSuccess?: (comment: Comment) => void;
}

const AddCommentButton: React.FC<AddCommentButtonProps> = ({ documentUuid, referenceId, onSuccess }) => {
  const { isReadOnly } = useRole();
  const username = useUsername();

  const [showText, setShowText] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const addCommentRef = useRef(null);

  const handleShowAddComment = () => {
    setShowText(true);

    setTimeout(() => {
      if (addCommentRef.current) {
        // @ts-ignore
        addCommentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleAddComment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const comment = await commentService.createComment({
      documentId: documentUuid,
      referenceId,
      author: 'Anonymous',
      content,
      isResolved: false,
      isDeleted: false,
      createdBy: username,
    });
    if (comment) {
      onSuccess && onSuccess(comment);
    }
    setContent('');
    setShowText(false);
    setLoading(false);
  };

  return showText ? (
    <Box
      component="form"
      onSubmit={handleAddComment}
      width={600}
      border="1px solid black"
      borderRadius={4}
      boxShadow={5}
      ref={addCommentRef}
    >
      <Box px={2} py={1}>
        <Typography variant="subtitle1" color="textPrimary" mb={1}>
          Anonymous
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          name="content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          disabled={isReadOnly}
        />
      </Box>
      <Box display="flex" justifyContent="end" gap={1} mx={2} my={1}>
        <Button color="success" variant="contained" type="submit" disabled={loading || isReadOnly}>
          Save
        </Button>
        <Button color="inherit" variant="contained" onClick={() => setShowText(false)} disabled={loading}>
          Cancel
        </Button>
      </Box>
    </Box>
  ) : (
    <Button sx={{ width: 600 }} color="secondary" onClick={handleShowAddComment} disabled={isReadOnly}>
      ADD COMMENT
    </Button>
  );
};

export default AddCommentButton;
