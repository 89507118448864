import React, { useEffect, useState } from 'react';
import { Box, Button, Popper, Switch, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { documentService } from '../../services/DocumentService';
import { DocumentDiffChangeMap, DocumentNode } from '../../types/RealtaDocument';
import TOCList from './TOCList';
import Close from '@mui/icons-material/Close';
import useRole from '../../hooks/useRole';

const TOC: React.FC<{
  documentUuid: string;
  diffChanges?: DocumentDiffChangeMap;
}> = ({ documentUuid, diffChanges }) => {
  const { isReadOnly } = useRole();
  const [loadComment, setLoadComment] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [headings, setHeadings] = useState<DocumentNode[]>([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const init = async () => {
      const headings = await documentService.getDocumentHeadingSections({ documentUuid });
      setHeadings(headings);
    };

    init();
  }, [documentUuid]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button onClick={handleClick} sx={{ color: '#000', p: 0, minWidth: 48 }} disableRipple>
        <MenuIcon />
      </Button>
      <Popper style={{ zIndex: 1002, maxWidth: '30w', width: '30vw' }} anchorEl={anchorEl} open={open}>
        <Box sx={{ p: 1, bgcolor: 'background.paper' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '12px !important',
                alignItems: 'center',
                flexShrink: 0,
              }}
            >
              {!isReadOnly && (
                <>
                  <Switch
                    size="small"
                    checked={loadComment}
                    onChange={() => setLoadComment(!loadComment)}
                    color="secondary"
                  />
                  <Typography fontSize={10} whiteSpace="nowrap">
                    Show Comments
                  </Typography>
                </>
              )}
            </Box>
            <Button
              color="inherit"
              disableRipple
              variant="contained"
              onClick={handleClose}
              sx={{ mb: 1, ml: 'auto', mr: 0.5, display: 'flex' }}
            >
              <Close color="inherit" />
            </Button>
          </Box>
          <TOCList
            headings={headings}
            documentUuid={documentUuid}
            loadComment={loadComment}
            diffChanges={diffChanges}
          />
        </Box>
      </Popper>
    </Box>
  );
};
export default TOC;
