import { ColorsByDeptLevel, DocumentNode } from '../types/RealtaDocument';
import { convertInchToPx } from './inchToPx';

export const ANNOTATION_WIDTH = 8;
export const MAX_ANNOTATION_HEIGHT = 9999;

export type Annotation = {
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  color: string;
  node: DocumentNode;
  type?: string;
};

export const generateAnnotations = ({
  nodes,
  page,
  componentHeight,
  rootHeight,
}: {
  nodes: DocumentNode[];
  page: number;
  componentHeight: number;
  rootHeight: number;
}): Annotation[] =>
  nodes.map((node, index) => {
    let startY, endY;

    // calculate startY by page
    // if page is less or equal startBoundryPage, startY is startBoundryY
    // if page is larger than startBoundryPage, startY is 0
    if (page <= (node.startBoundryPage || 0)) {
      startY = convertInchToPx({ inch: node.startBoundryY, componentLength: componentHeight, rootHeight });
    } else {
      startY = 0;
    }

    // calculate endY by page
    // if page is less endBoundryPage, endY is MAX_ANNOTATION_HEIGHT
    // if page is larger or equal than endBoundryPage, endY is endBoundryY
    if (page < node.endBoundryPage) {
      endY = MAX_ANNOTATION_HEIGHT;
    } else {
      endY = convertInchToPx({ inch: node.endBoundryY, componentLength: componentHeight, rootHeight });
    }

    return {
      startX: index * ANNOTATION_WIDTH * 1.5 + ANNOTATION_WIDTH,
      endX: index * ANNOTATION_WIDTH * 1.5 + ANNOTATION_WIDTH,
      startY,
      endY,
      color: ColorsByDeptLevel[index],
      node,
      type: node.type,
    };
  });
