import fetch from 'node-fetch';

export const SOURCE_URL = 'https://ingestion-analysis-process-doc-bucket.s3.ap-southeast-2.amazonaws.com';

export async function getPdfFromUrl(url: string): Promise<ArrayBuffer> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch PDF: ${response.statusText}`);
  }
  const arrayBuffer = await response.arrayBuffer();
  return arrayBuffer;
}
