import React from 'react';
import { Box, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import useRole from '../../hooks/useRole';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCreateTag } from '../../services/queries';

interface AddTagButtonProps {
  nodeUuid: string;
  onSuccess?: () => void;
}

type CreateTagType = {
  name: string;
};

const schema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required('Name is required')
      .test('notBlank', 'Name is required', (value) => !!value),
  });

const AddTagButton: React.FC<AddTagButtonProps> = ({ nodeUuid, onSuccess }) => {
  const { isReadOnly } = useRole();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: createTag, isLoading } = useCreateTag();

  const { handleSubmit, errors, control, reset } = useForm<CreateTagType>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema()),
  });

  const [open, setOpen] = React.useState<boolean>(false);

  const isDisabled = isReadOnly || isLoading;

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async (data: CreateTagType) => {
    try {
      await createTag({ nodeUuid, name: data.name });
      onSuccess && onSuccess();
      handleClose();
      enqueueSnackbar(`Tag ${data.name} created successfully`, { variant: 'success' });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error?.message ? error.message.replace('GraphQL error: ', '') : 'Failed to create tag', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Button color="secondary" variant="outlined" onClick={() => setOpen(true)} disabled={isReadOnly}>
        ADD TAG
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create Tag</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Controller
              control={control}
              name="name"
              render={({ ...fields }) => (
                <TextField
                  {...fields}
                  label="Name"
                  fullWidth
                  color="secondary"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  disabled={isDisabled}
                  sx={{ my: 1 }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" color="inherit" onClick={handleClose} disabled={isDisabled}>
              Cancel
            </Button>
            <Button type="submit" autoFocus color="secondary" disabled={isDisabled}>
              Create
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AddTagButton;
