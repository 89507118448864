import React, { Suspense, memo } from 'react';
import sizeMe, { SizeMeProps } from 'react-sizeme';

const CodeBlockCodeMirror = React.lazy(() => import('./CodeBlockCodeMirror'));

type CodeBlockInputEditorProps = {
  nodeId: string;
  size: SizeMeProps['size'];
  rule: string;
  hightLight: boolean;
  lineWrapping: boolean;
  readOnly?: boolean;
  onRuleChange: (rule: string) => void;
};

const CodeBlockInput: React.FC<CodeBlockInputEditorProps> = memo(
  ({ nodeId, rule, hightLight, lineWrapping, readOnly, onRuleChange }: CodeBlockInputEditorProps) => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <CodeBlockCodeMirror
          nodeId={nodeId}
          value={rule}
          onChange={onRuleChange}
          options={{
            placeholder: 'type rule....',
          }}
          isActive
          readOnly={!!readOnly}
          lineWrapping={lineWrapping}
          hightLight={hightLight}
        />
      </Suspense>
    );
  }
);

export default sizeMe({
  monitorHeight: true,
  monitorWidth: false,
  refreshRate: 500,
  noPlaceholder: true,
})(CodeBlockInput);
