import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { DocumentNode, Test } from '../../types/RealtaDocument';
import { documentService } from '../../services/DocumentService';
import { settingSelectors, updateSplit2Size } from '../../store/setting';
import { useDispatch, useSelector } from 'react-redux';
import { TestEditorSplitPane } from './TestEditorSplitPane';
import { TestCaseListPanel } from './TestCaseListPanel';
import { TestCasePanel } from './TestCasePanel';
import { trackEvent } from '../../services/analytics';
import icon from '../../images/icon.svg';
import { loadTests, testSelectors } from '../../store/test';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useBeforeUnload } from 'react-use';

const TestSection: React.FC<{ node: DocumentNode; onChangeAlert: (value: boolean) => void }> = ({
  node,
  onChangeAlert,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const tests = useSelector(testSelectors.tests);
  const [originalTests, setOriginalTests] = useState<Test[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const loadedTests = await documentService.getTestsByNodeId({ parentNodeUuid: node.uuid });
        dispatch(loadTests(loadedTests));
        setOriginalTests(loadedTests);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to fetch test cases', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [node]);

  useBeforeUnload(
    _.trim(JSON.stringify(tests)) !== _.trim(JSON.stringify(originalTests)),
    'Changes you made may not be saved.'
  );

  const isSegmentPanelClosed = useSelector(settingSelectors.isSegmentPanelClosed);
  const isTestCasePanelClosed = useSelector(settingSelectors.isTestCasePanelClosed);

  const split2Props: any = {
    primary: 'first',
    allowResize: isTestCasePanelClosed ? false : true,
    size: '33%',
    pane1Style:
      (!isSegmentPanelClosed && {
        overflow: 'auto',
      }) ||
      (!isTestCasePanelClosed && {
        minWidth: '230px',
        maxWidth: '70%',
      }) ||
      {},
  };

  const isSplit2HoverDisabled = isTestCasePanelClosed;
  const handleSplit2Drag = (size: number) => dispatch(updateSplit2Size(size));

  const handleSplit2DoubleClick = () => {
    !isTestCasePanelClosed && dispatch(updateSplit2Size('50%'));
    trackEvent.splitPaneDoubleClick();
  };

  const onSave = (test: Test) => {
    const updatedTests = tests.map((t) => (t.uuid === test.uuid ? test : t));
    dispatch(loadTests(updatedTests));
    setOriginalTests(updatedTests);
    onChangeAlert(false);
  };

  const onTestsChange = () => {
    onChangeAlert(true);
  };

  if (loading) {
    return (
      <Box boxShadow={5} height="100%" display="flex" justifyContent="center" alignItems="center">
        <Box component="img" src={icon} alt="Loading" width="200" height={200} sx={{ opacity: 0.5 }} />
      </Box>
    );
  }
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      bgcolor="transparent"
      gap={3}
      overflow="auto"
      p={1}
    >
      <Box>
        <TestEditorSplitPane
          onDragFinished={handleSplit2Drag}
          onResizerDoubleClick={handleSplit2DoubleClick}
          hoverDisabled={isSplit2HoverDisabled}
          {...split2Props}
        >
          <TestCaseListPanel tests={tests} sectionId={node.uuid} />
          <TestCasePanel tests={tests} sectionId={node.uuid} onSave={onSave} onTestsChange={onTestsChange} />
        </TestEditorSplitPane>
      </Box>
    </Box>
  );
};
export default TestSection;
