export type ReasonerSummary = {
  facts: Fact[];
  missingFacts: string[];
};

export type Fact = {
  name: string;
  modes: string[];
};

export class InputFact {
  name: string;
  mode: string;

  constructor(fact: string, mode: string) {
    this.name = fact;
    this.mode = mode;
  }
}
