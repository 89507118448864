import React, { useMemo, useState } from 'react';
import { ToolbarIconButton } from './ToolbarIconButton';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteTestCaseDialog } from './DeleteTestCaseDialog';
import { makeStyles } from '@mui/styles';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import WrapTextIcon from '@mui/icons-material/WrapText';
import AutoSuggestIcon from '@mui/icons-material/Spellcheck';
import grey from '@mui/material/colors/grey';
import classNames from 'classnames';
import { addTest, setAutoSuggestions, setWordWrap, testSelectors } from '../../store/test';
import { documentService } from '../../services/DocumentService';
import useUsername from '../../hooks/useUsername';

const useStyles = makeStyles(() => ({
  gutters: {
    padding: '4px 12px',
  },
  wordWrap: {
    borderRadius: '5px',
    backgroundColor: grey[400],
  },
  autoSuggest: {
    borderRadius: '5px',
    backgroundColor: grey[400],
  },
}));

const TestCaseMoreOption: React.FC<{ isReadOnly?: boolean }> = ({ isReadOnly }) => {
  const classes = useStyles();
  const username = useUsername();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);
  const dispatch = useDispatch();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const activeTestCaseId = useSelector(testSelectors.selectedTestId);

  const wordWrap = useSelector(testSelectors.wordWrap);
  const autoSuggest = useSelector(testSelectors.autoSuggestions);
  const selectedTest = useSelector(testSelectors.selectedTest);
  const queryParams = new URLSearchParams(location.search);

  const sectionId = useMemo(
    () => queryParams.get('section-id'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams.get('section-id')]
  );

  const handleToggle = (event: any) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClone = async () => {
    if (selectedTest) {
      handleClose();
      const clonedTest = await documentService.createTest({
        ...selectedTest,
        parentNode: sectionId || '',
        description: selectedTest.description || '',
        createdBy: username,
      });
      clonedTest && dispatch(addTest(clonedTest));
    }
  };

  const handleDelete = () => {
    handleClose();
    setOpenDeleteConfirmation(true);
  };

  const handleWordWrap = () => {
    handleClose();
    dispatch(setWordWrap(!wordWrap));
  };

  const handleAutoSuggest = () => {
    handleClose();
    dispatch(setAutoSuggestions(!autoSuggest));
  };

  const menuItems = [
    {
      title: 'Clone',
      onClick: handleClone,
      icon: <FileCopyIcon fontSize="small" />,
      disabled: isReadOnly,
    },
    {
      title: 'Delete',
      onClick: handleDelete,
      icon: <DeleteIcon fontSize="small" />,
      disabled: isReadOnly,
    },
    {
      title: wordWrap ? 'Word wrap off' : 'Word wrap on',
      onClick: handleWordWrap,
      icon: (
        <WrapTextIcon
          fontSize="small"
          className={classNames({
            [classes.wordWrap]: wordWrap,
          })}
        />
      ),
    },
    {
      title: autoSuggest ? 'Auto suggest off' : 'Auto suggest on',
      onClick: handleAutoSuggest,
      icon: (
        <AutoSuggestIcon
          fontSize="small"
          className={classNames({
            [classes.autoSuggest]: autoSuggest,
          })}
        />
      ),
    },
  ];

  return (
    <>
      <ToolbarIconButton aria-label="more actions" title="More actions" onClick={handleToggle} ref={anchorRef}>
        <MoreVertIcon fontSize="small" />
      </ToolbarIconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal sx={{ zIndex: 11 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocus={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {menuItems.map(({ title, onClick, icon, disabled }) => {
                    return (
                      <MenuItem
                        onClick={onClick}
                        classes={{
                          gutters: classes.gutters,
                        }}
                        key={title}
                        disabled={disabled}
                      >
                        <span style={{ marginRight: '20px' }}>{title}</span>
                        {icon}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <DeleteTestCaseDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        testCaseId={activeTestCaseId || ''}
      />
    </>
  );
};

export { TestCaseMoreOption };
