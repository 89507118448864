import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import { DocumentNode, LinkedRtp, RealtaDocument } from '../../types/RealtaDocument';
import References from './References';
import ReferencedBy from './ReferencedBy';
import { documentService } from '../../services/DocumentService';
import Tags from './Tags';

const Graph = React.lazy(() => import('./Graph'));

const GraphView: React.FC<{
  document: RealtaDocument;
  node: DocumentNode;
  size: { width: number; height: number };
}> = ({ size, node, document }) => {
  const [linkedRtps, setLinkedRtps] = useState<LinkedRtp[]>([]);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  useEffect(() => {
    const init = async () => {
      const linkedRtps = await documentService.getLinkedRtp(node.uuid);
      setLinkedRtps(linkedRtps);
    };

    init();
  }, [node]);

  const formatDescription = (description: string) => {
    const formattedDesc = description.replace(/^"|"$/g, '');
    return formattedDesc.charAt(0).toUpperCase() + formattedDesc.slice(1);
  };

  return (
    <Box
      boxShadow={5}
      height="100%"
      p={1}
      sx={{
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box position="relative" minHeight={48}>
        <Button
          color="secondary"
          variant="contained"
          sx={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? 'Show Graph' : 'Hide Graph'}
        </Button>
        <Box display={isCollapsed ? 'none' : 'inherit'}>
          <Graph size={{ width: size.width, height: size.height * 0.7 }} node={node} document={document} />
        </Box>
      </Box>
      {linkedRtps.length > 0 && (
        <Card sx={{ p: 2, mx: 1, mt: 1 }}>
          <Typography variant="h6" fontWeight="bold" mb={2}>
            Regulatory Touch Points
          </Typography>
          {linkedRtps.map((linkedRtp, i) => (
            <Button
              key={i}
              color="inherit"
              href={`${process.env.RTP_ENDPOINT}/rtp/${linkedRtp.name}`}
              target="_blank"
              sx={{
                display: 'block',
                textTransform: 'none',
                fontWeight: 'normal',
                textDecoration: 'underline !important',
                overflow: 'hidden',
                textOverflow: 'hidden',
                mb: 0.5,
              }}
            >
              {formatDescription(linkedRtp.description)}
            </Button>
          ))}
        </Card>
      )}

      <Box display="flex" flexDirection="column">
        <Tags documentNode={node} />
        <References documentNode={node} />
        <ReferencedBy documentNode={node} />
      </Box>
    </Box>
  );
};
export default memo(GraphView);
