import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RatTestCodeMirror } from './RatTestCodeMirror';
import { testSelectors, updateTest } from '../../store/test';

const RequiredResultsInput: React.FC<{ isReadOnly?: boolean; onChange?: () => void }> = ({ isReadOnly, onChange }) => {
  const dispatch = useDispatch();
  const selectedTest = useSelector(testSelectors.selectedTest);
  const wordWrap = useSelector(testSelectors.wordWrap);
  const requiredResults = selectedTest?.requiredResults || '';
  const [value, setValue] = useState(requiredResults);

  useEffect(() => {
    setValue(requiredResults);
  }, [requiredResults]);

  const dispatchChange = (value: string) => {
    selectedTest && dispatch(updateTest({ ...selectedTest, requiredResults: value }));
    onChange && onChange();
  };

  const handleChange: any = (value: any) => {
    setValue(value);
    dispatchChange(value);
  };

  return (
    <RatTestCodeMirror
      value={value}
      onChange={handleChange}
      options={{
        placeholder: 'type required results',
      }}
      readOnly={isReadOnly}
      lineWrapping={wordWrap}
    />
  );
};

export { RequiredResultsInput };
