import React, { useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import ReferenceItem from './ReferenceItem';
import { useSnackbar } from 'notistack';
import { DocumentNode, Reference } from '../../types/RealtaDocument';
import { documentService } from '../../services/DocumentService';

const References: React.FC<{
  documentNode: DocumentNode;
}> = ({ documentNode }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [references, setReferences] = useState<Reference[]>([]);

  useEffect(() => {
    const init = async () => {
      try {
        const data = await documentService.getSectionReferences({
          uuid: documentNode.uuid,
          upstream: false,
          rootNodeIds: [documentNode.uuid],
        });
        if (data) {
          setReferences(data);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to fetch references', { variant: 'error' });
      }
    };

    init();
  }, [documentNode]);

  return references.length ? (
    <Card sx={{ p: 2, mx: 1, mt: 1, mb: 2 }}>
      <Typography variant="h6" fontWeight="bold" mb={2}>
        References
      </Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        {references.map((reference, i) => (
          <ReferenceItem
            key={reference.section.uuid}
            data={reference}
            position={i + 1}
            upstream={false}
            rootNodeIds={[documentNode.uuid, reference.section.uuid || '']}
          />
        ))}
      </Box>
    </Card>
  ) : (
    <></>
  );
};
export default References;
