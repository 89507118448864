import React, { useRef } from 'react';
import {
  Typography,
  Box,
  IconButton,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from '@mui/material';
import { Delete, Check, Reply } from '@mui/icons-material';
import { Comment } from '../../types/Comment';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { commentService } from '../../services/CommentService';
import useRole from '../../hooks/useRole';
import useUsername from '../../hooks/useUsername';

interface CommentProps {
  documentUuid: string;
  referenceId: string;
  comment: Comment;
  disableReply?: boolean;
  disableResolve?: boolean;
  onResolve?: (id: string) => void;
  onDelete?: (id: string, parentId?: string) => void;
  onReply?: (id: string, comment: Comment) => void;
}

const CommentItem: React.FC<CommentProps> = ({
  documentUuid,
  referenceId,
  comment,
  disableReply,
  disableResolve,
  onDelete,
  onResolve,
  onReply,
}) => {
  const { isReadOnly } = useRole();
  const username = useUsername();

  const [showReply, setShowReply] = React.useState(false);
  const [content, setContent] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
  const [showResolveDialog, setShowResolveDialog] = React.useState<boolean>(false);
  const addReplyRef = useRef(null);

  const handleResolveComment = async () => {
    await commentService.resolveComment(comment._id, username);
    setShowResolveDialog(false);
    onResolve && onResolve(comment._id);
  };

  const handleDeleteComment = async (id: string, parentId?: string) => {
    await commentService.deleteComment(id, username);
    setShowDeleteDialog(false);
    onDelete && onDelete(id, parentId);
  };

  const handleShowReply = () => {
    setShowReply(true);
    setTimeout(() => {
      if (addReplyRef.current) {
        // @ts-ignore
        addReplyRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleAddReply = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const reply = await commentService.replyComment(comment._id, {
      documentId: documentUuid,
      referenceId,
      author: 'Anonymous',
      content,
      isResolved: false,
      isDeleted: false,
      createdBy: username,
    });
    if (reply) {
      onReply && onReply(comment._id, reply);
    }
    setContent('');
    setShowReply(false);
    setLoading(false);
  };

  return (
    <Box>
      <Box width={600} border="1px solid black" borderRadius={4} boxShadow={5}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={1}
          borderBottom="1px solid black"
        >
          <Typography variant="subtitle2" color="success">
            {comment.isResolved ? 'Resolved' : ''}
          </Typography>
          <Box display="flex" justifyContent="right" alignItems="center" gap={1}>
            {!comment.isResolved && !disableResolve && (
              <IconButton color="success" size="small" onClick={() => setShowResolveDialog(true)} disabled={isReadOnly}>
                <Check />
              </IconButton>
            )}
            {!disableReply && (
              <IconButton size="small" onClick={handleShowReply} disabled={isReadOnly}>
                <Reply />
              </IconButton>
            )}
            <IconButton color="error" size="small" onClick={() => setShowDeleteDialog(true)} disabled={isReadOnly}>
              <Delete />
            </IconButton>
          </Box>
        </Box>
        <Box px={2} py={1}>
          <Typography variant="subtitle1" color="textPrimary">
            {comment.author}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {comment.content}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" textAlign="right" fontSize={12} mt={1}>
              {comment.createdBy}
            </Typography>
            <Typography variant="body2" textAlign="right" fontSize={12} mt={1}>
              {formatDistanceToNow(parseISO(comment.createdAt), { addSuffix: true })}
            </Typography>
          </Box>
        </Box>
      </Box>
      {comment.replies && comment.replies.length > 0 && (
        <Box ml={4} mt={1} display="flex" flexDirection="column" gap={1}>
          {comment.replies.map((reply) => (
            <CommentItem
              key={reply._id}
              documentUuid={documentUuid}
              referenceId={referenceId}
              comment={reply}
              disableReply
              disableResolve
              onDelete={() => handleDeleteComment(reply._id, comment._id)}
            />
          ))}
        </Box>
      )}
      {!disableReply && showReply && (
        <Box
          component="form"
          onSubmit={handleAddReply}
          width={600}
          border="1px solid black"
          borderRadius={4}
          boxShadow={5}
          ml={4}
          mt={1}
          ref={addReplyRef}
        >
          <Box px={2} py={1}>
            <Typography variant="subtitle1" color="textPrimary" mb={1}>
              Anonymous
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              name="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </Box>
          <Box display="flex" justifyContent="end" gap={1} mx={2} my={1}>
            <Button color="success" variant="contained" type="submit" disabled={loading || isReadOnly}>
              Save
            </Button>
            <Button color="inherit" variant="contained" onClick={() => setShowReply(false)} disabled={loading}>
              Cancel
            </Button>
          </Box>
        </Box>
      )}
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DialogTitle>Delete Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this comment?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteComment(comment._id)} color="secondary" autoFocus disabled={isReadOnly}>
            Yes
          </Button>
          <Button onClick={() => setShowDeleteDialog(false)} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showResolveDialog} onClose={() => setShowResolveDialog(false)}>
        <DialogTitle>Resolve Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to resolve this comment?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResolveComment} color="secondary" autoFocus disabled={isReadOnly}>
            Yes
          </Button>
          <Button onClick={() => setShowResolveDialog(false)} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CommentItem;
