import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Typography } from '@mui/material';
import { ToolbarVertical } from '../ui/ToolbarVertical/ToolbarVertical';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';
import CodeGenDebugStatus from './CodeGenDebugStatus';

const titleStyle = {
  fontWeight: 600,
  fontSize: '.85em',
};

const CodeGenDebugPanelClosed = ({
  onOpenPanel,
  isSuccess = false,
}: {
  onOpenPanel: () => void;
  isSuccess?: boolean;
}) => {
  const handleExpand = (event: any) => {
    event.stopPropagation();
    onOpenPanel();
  };

  return (
    <ToolbarVertical variant="dense" disableGutters={true} title="Open" onClick={handleExpand} sx={{ height: '100%' }}>
      <ToolbarIconButton onClick={handleExpand} title="Open">
        <ChevronLeftIcon />
      </ToolbarIconButton>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        sx={{ writingMode: 'vertical-rl', textOrientation: 'mixed', marginTop: '5px' }}
      >
        <Typography sx={titleStyle}>CodeGenDebug</Typography> <CodeGenDebugStatus isSuccess={isSuccess} />
      </Box>
    </ToolbarVertical>
  );
};

export { CodeGenDebugPanelClosed };
