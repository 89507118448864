import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/material';
import { ToolbarVertical } from '../ui/ToolbarVertical/ToolbarVertical';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';

const titleStyle = {
  marginTop: '5px',
  writingMode: 'vertical-rl',
  textOrientation: 'mixed',
  fontWeight: 600,
  fontSize: '.85em',
};

const AtomPanelClosed = ({ onOpenPanel }: { onOpenPanel: () => void }) => {
  const handleExpand = (event: any) => {
    event.stopPropagation();
    onOpenPanel();
  };

  return (
    <ToolbarVertical variant="dense" disableGutters={true} title="Open" onClick={handleExpand} sx={{ height: '100%' }}>
      <ToolbarIconButton onClick={handleExpand} title="Open">
        <ChevronLeftIcon />
      </ToolbarIconButton>
      <Box sx={titleStyle}>Atoms</Box>
    </ToolbarVertical>
  );
};

export { AtomPanelClosed };
